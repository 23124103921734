import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Container,
    Collapse,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from "reactstrap";

import ScrollspyNav from "./scrollSpy";

//Import Images
import logolight from "../../assets/images/logo.png";
import logodark from "../../assets/images/logo.png";

class NavbarPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenMenu: false
        };
    }

    toggle = () => {
        this.setState({ isOpenMenu: !this.state.isOpenMenu });
    };
   
    render() {
      
        //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
        let targetId = this.props.navItems.map((item) => {
            return (
                item.idnm
            )
        });
        return (
            <React.Fragment>
                <Navbar expand="lg" fixed="navbar-fixed-top" className={"navbar-custom  sticky sticky-dark " + this.props.navClass}>
                    <Container>
                        <NavbarBrand className="logo text-uppercase" href="/">
                            {
                                this.props.imglight === true ?
                                    <img src={logolight} alt="" className="logo-light" height="50" />
                                    : <img src={logodark} alt="" className="logo-dark" height="50" />
                            }
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} ><i className="mdi mdi-menu"></i></NavbarToggler>

                        <Collapse id="navbarCollapse" isOpen={this.state.isOpenMenu} navbar>
                            {/* <ScrollspyNav
                                scrollTargetIds={targetId}
                                scrollDuration="200"
                                headerBackground="true"
                                activeNavClass="active"
                                className="navbar-collapse"
                            > */}
                            <Nav navbar className="m-auto navbar-center" id="mySidenav">
                                <NavItem style={{ color: 'black' }} className={'Home' === "Home" ? "active" : ""}>
                                    <NavLink  ><Link to="/" style={{color:'#02035E'}}>  Home</Link></NavLink>

                                </NavItem>
                                <NavItem style={{ color: 'black' }} className={'aboutus' === "Home" ? "active" : ""}>
                                    <NavLink ><Link to="/aboutus" style={{color:'#02035E'}}> About Us</Link></NavLink>

                                </NavItem>
                                <NavItem style={{ color: 'black' }} >
                                    {/* <NavLink href={"#"} > services</NavLink> */}
                                    <UncontrolledDropdown setActiveFromChild>
                                        <DropdownToggle
                                            caret
                                            className="nav-link"
                                            tag="a"
                                        >
                                            <Link to="#services" style={{color:'#02035E'}}> 
                                            Services</Link>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                // active
                                                // href="/customerServices"
                                                // tag="a"
                                            >
                                                 <Link to="/customerServices" style={{color:'#02035E'}}>Customer Service </Link>
                                                
                                            </DropdownItem>
                                            <DropdownItem
                                                // active
                                                // href="/telemarketing"
                                                // tag="a"
                                            >
                                                 <Link to="/telemarketing" style={{color:'#02035E'}}>  Telemarketing</Link>
                                               
                                            </DropdownItem>
                                            <DropdownItem
                                                // active
                                                // href="/bookkeeping"
                                                // tag="a"
                                            >
                                                 <Link to="/bookkeeping" style={{color:'#02035E'}}>  Book Keeping</Link>
                                               
                                            </DropdownItem>
                                            <DropdownItem
                                                // active
                                                // href="/realestate"
                                                // tag="a"
                                            >
                                                 <Link to="/realestate" style={{color:'#02035E'}}> Real Estate</Link>
                                                
                                            </DropdownItem>
                                            <DropdownItem
                                                // active
                                                // href="/weddev"
                                                // tag="a"
                                            >
                                                 <Link to="/weddev" style={{color:'#02035E'}}>  Web Developement</Link>
                                               
                                            </DropdownItem>
                                            <DropdownItem
                                                // active
                                                // href="/socialmedia"
                                                // tag="a"
                                            >
                                                 <Link to="/socialmedia" style={{color:'#02035E'}}>  Social Media Management</Link>
                                               
                                            </DropdownItem>
                                            <DropdownItem
                                                // active
                                                // href="/productdesign"
                                                // tag="a"
                                            >
                                                 <Link to="/productdesign" style={{color:'#02035E'}}>  Product Designer</Link>
                                               
                                            </DropdownItem>
                                            
                                            <DropdownItem
                                                // active
                                                // href="/generaladmin"
                                                // tag="a"
                                            >
                                                <Link to="/generaladmin" style={{color:'#02035E'}}>General Admin Task </Link>
                                                
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </NavItem>
                                <NavItem style={{ color: 'black' }} >
                                    <NavLink  ><Link to="/contactus" style={{color:'#02035E'}}>Contact Us</Link></NavLink>

                                </NavItem>

                          
                                
                                {/* {this.props.navItems.map((item, key) => (
                                    <NavItem style={{ color: 'black' }} key={key} className={item.navheading === "Home" ? "active" : ""}>
                                        <NavLink href={"#" + item.idnm} > {item.navheading}</NavLink>

                                    </NavItem>
                                ))} */}
                                {/* key={key} className={item.navheading === "Home" ? "active" : ""} */}
                                <NavItem style={{ marginLeft:'30px' }} > 
                            <NavLink  ><p style={{color:'#A47229'}}><span>Want to make enquiries? </span>+1 614-647-7088</p></NavLink>
                                 </NavItem>
                            </Nav>
                                            
 <a href='https://dashboard.bestassistantusa.com'> <Button  color="#A47229" style={{ color: '#000' }} >Login</Button></a>
<Link to='/signup'><Button color="#A47229" style={{ backgroundColor: '#A47229', color: '#ffff' }} className="btn-rounded navbar-btn">Sign Up</Button></Link>

                         
                            {/* </ScrollspyNav> */}
                        </Collapse> 
                        
                    </Container>
                
                </Navbar>
       
                        
            </React.Fragment>
        );
    }
}

export default NavbarPage;

