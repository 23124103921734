import React, { Component, useState ,Fragment} from 'react';

import { Container, Row, Button, Col, CardBody, Nav,  Input, Form,NavItem, NavLink, TabContent, FormGroup, TabPane, Label } from "reactstrap";

import { Link } from "react-router-dom";
import homeImg from "../../assets/images/image6.png";
import LoadingPage from "../../components/loadingpage";
import { errorToast, successToast } from "../../components/toasts";
// import { Form,Field, Formik, FormikErrors,ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
//Import Images
import { BASE_URL } from '../../config';

import  validator from 'validator'
const Faqs = () => {
  const [loading, setLoading] = useState(false);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone] = useState("");
  const [message, setMessage] = useState("");
    const handleSubmit = () => {
      setLoading(true)
      // Alert the input values of the form that we filled
      let data = {fullname,email,phone_number,message}

      if(fullname ==""){
        errorToast('FullName Field Is Empty');
        setLoading(false);
      }
      else if(email ==""){
        errorToast('Email Field Is Empty');
        setLoading(false);
      }
      else if(!validator.isEmail(email)){
        errorToast('Please Enter a Valide Email Address');
        setLoading(false);
      }
      else if(phone_number ==""){
        errorToast('Phone Number Field Is Empty');
        setLoading(false);
      }
      else if(message ==""){
        errorToast('Message Field Is Empty');
        setLoading(false);
      }else{
        console.log('-------',data)
      axios({
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: `${BASE_URL}/api/contact_us`,
        data: data
      }).then((response) => {
        setTimeout(() => {
          setLoading(false);
          successToast("Sent Successfully");
          window.location.reload()
        }, 3000);
      }, (error) => {
        console.log("00",error)
        errorToast(`${error}`)
        setLoading(false);
      });;
    }
    };
  return (
    <React.Fragment>
      <section className="section " id="services" style={{ background: `url(${homeImg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: '100%' }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="hero-wrapper ">

                <h1 className="hero-title " style={{ textAlign: 'center', color: 'white' }}>Contact Us</h1>

              </div>

            </Col>
          </Row>
        </Container>

      </section>
      <section className="section " id="services" style={{ backgroundColor: '#FFFF', }}>
        <Container >
          <Row className="justify-content-center" >
            <Col lg={12} >
              <div className="hero-wrapper mb-4" style={{width: '500px',margin:'auto'}}>
                <p style={{ color: "#02035E", textAlign: 'center',   }}>Welcome to Best Assistant USA, where we bring innovation, efficiency, and a personal touch to the world
                  of virtual support.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
     
              {/* <div className="subscribe" > */}
                               
              {/* <Formik
                    validationSchema={ContactSchema}
                    initialValues={{ name: "" ,email: "",  phone: "", message: "" }}
                    onSubmit= {handleSubmit}
                  >
                    {() => ( */}
                <Form  style={{width: '500px',margin:'auto'}}>
                  <Row>
                    <label for="exampleSelect" style={{ marginLeft: '10px', color: 'black' }}>
                      Name<span style={{ color: 'red' }}>*</span>
                    </label>
                    <Col xl={12} md={8}>
                      <div>
                        <Input type="text" value={fullname} className="form-control" name="name" id="name" placeholder="Enter your Full Name" style={{ width: '500px', height: '40px', borderRadius: '35px', borderColor: 'black', }}  onChange={(text) => {setFullname(text.target.value)}}/>
                      </div>
                    </Col>
              
                  </Row>
                  <br />
                  <Row>
                    <label for="exampleSelect" style={{ marginLeft: '10px', color: 'black' }}>
                      Email Address <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Col xl={12} md={8}>
                      <div>
                        <Input type="email" value={email} className="form-control"  name="email" placeholder="Enter your Email" style={{ width: '500px', height: '40px', borderRadius: '35px', borderColor: 'black', }}  onChange={(text) => {setEmail(text.target.value)}} />
                      </div>
                    </Col>
                 
                  </Row>
                  <br />
                  <Row>
                    <label for="exampleSelect" style={{ marginLeft: '10px', color: 'black' }}>
                      Phone Number <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Col  md={8} xl={12}>
                      <div>
                        <Input type="text" value={phone_number} className="form-control" name="phone" placeholder="Enter your Phone Number" style={{ width: '500px', height: '40px', borderRadius: '35px', borderColor: 'black', }}  onChange={(text) => {setPhone(text.target.value)}}/>
                      </div>
                    </Col>
                  
                  </Row>
                  <br />
                  <Row>
                    <label for="exampleSelect" style={{ marginLeft: '10px', color: 'black' }}>
                      Message <span style={{ color: 'red' }}>*</span>
                    </label>
                    <Col  md={8} xl={12}>
                      <div>

                        <FormGroup>
                          <textarea className="form-control" name="message" role='8' style={{ height: '150px', width: '500px' }}  value={message} placeholder="Message" onChange={(text) => {setMessage(text.target.value)}}>

                          </textarea>
                        </FormGroup>
                      
                      </div>
                    </Col>

                  </Row>
                  <br />


                  <Row>

                    <Col xl={12} md={8} style={{ margin: 'auto' }}>
                      <div className="mt-3 mt-md-0" >
                        
                      <Fragment >
                          {loading ? (
                            <LoadingPage />
                          ) : (
                            <Button type="button" style={{ backgroundColor: '#A47229', height: '50px', borderRadius: '35px', width: '500px' }} block onClick={handleSubmit}>Submit</Button>
                          )}
                        </Fragment>
                        
                      </div>
                    </Col>
                  </Row>
                </Form>
            
              {/* </div> */}
            
          </Row>




        </Container>

      </section>
    </React.Fragment>
  );
}


export default Faqs;