import React, { Component } from 'react';
import { Container, Row,Button, Col, CardBody, Nav, Form, Input,NavItem, NavLink, TabContent, FormGroup, TabPane,Label } from "reactstrap";

import classnames from 'classnames';
import { Link } from "react-router-dom";
//Import Section Title
import SectionTitle from "../common/section-title";
import start1 from "../../assets/images/start1.png";
import start2 from "../../assets/images/start2.png";
//Import Pricing
import PricingBox from './pricing-box';
import { DatePicker,TimePicker ,Typography } from 'antd';

const onChange = (date, dateString) => {
  console.log(date, dateString);
};
class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pricings1 : [
               { title : "Economy", price : 19, duration : "Mo",
                    features : [
                        { title : "Bandwidth", value : "1GB" },
                        { title : "Onlinespace ", value : "50MB" },
                        { title : "Support", value : "No" },
                    ]
                },
                { title : "Premium", price : 29, duration : "Mo",
                    features : [
                        { title : "Bandwidth", value : "2GB" },
                        { title : "Onlinespace ", value : "1GB" },
                        { title : "Support", value : "No" },
                    ]
                },
                { title : "Developer", price : 39, duration : "Mo",
                    features : [
                        { title : "Bandwidth", value : "3GB" },
                        { title : "Onlinespace ", value : "2GB" },
                        { title : "Support", value : "Yes" },
                    ]
                },
            ],
            pricings2 : [
                { title : "Economy", price : 19, duration : "Year",
                     features : [
                         { title : "Bandwidth", value : "1GB" },
                         { title : "Onlinespace ", value : "50MB" },
                         { title : "Support", value : "No" },
                     ]
                 },
                 { title : "Premium", price : 29, duration : "Year",
                     features : [
                         { title : "Bandwidth", value : "2GB" },
                         { title : "Onlinespace ", value : "1GB" },
                         { title : "Support", value : "No" },
                     ]
                 },
                 { title : "Developer", price : 39, duration : "Year",
                     features : [
                         { title : "Bandwidth", value : "3GB" },
                         { title : "Onlinespace ", value : "2GB" },
                         { title : "Support", value : "Yes" },
                     ]
                 },
             ],
            activeTab: '1',
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
   
    render() {
        function name(params) {
        
        }
        return (
            <React.Fragment>
        <section className="section " id="pricing" style={{width:'994px', margin:'auto',}}>
            <Container>

            { 
            /* <SectionTitle
                subtitle="Pricing"
                title="Choose your Plan"
                desc="It will be as simple as occidental in fact, it will be Occidental."
            />*/
            }

                     <h1 className="hero-title mb-4" style={{color:'#02035E'}}>Book a Call</h1>
                     <p  style={{textAlign:'left',color:'black'}}>We’d like to spend a few minutes understanding your needs and answering any questions you may have about how working with a Virtual Assistant works. If you feel like it’s a fit, we’ll hand-match you with a thoroughly vetted, dedicated assistant within 72 hours!</p>

                     <h3 className="mb-4" style={{color:'#02035E'}}>Select your preference</h3>
                <Row>
                    <Col md={8} style={{margin:'auto'}}>
                    <iframe class="calendly-inline-widget" src="https://calendly.com/amazingpropertiesheathllc/best-assistant-call"  style={{width: '100%', border: 'none', height: '450px', overflow: 'hidden', margin: 'auto'}}
        scrolling="yes"></iframe><script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
                   
                    {/* <div id="calendly-embed" style="min-width:320px;height:700px;"></div> */}
                   
                    
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflow: 'hidden' }}>
      <iframe
        title="Schedule an appointment"
        src="https://api.leadconnectorhq.com/widget/booking/S6NpXxsgW3O63YgP4kgr" 
        style={{width: '60%', border: 'none', height: '750px', overflow: 'hidden', marginTop: '20px'}}
        scrolling="yes"
        id="S6NpXxsgW3O63YgP4kgr_1710426170937">
      </iframe>
      <br />
      <script src="https://link.msgsndr.com/js/form_embed.js" type="text/javascript"></script>
    </div> */}
                      </Col>
                </Row>

                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Pricing;


