import Index1 from "./pages/Index1/Index1";
import Index2 from "./pages/Index2/Index2";
import Index3 from "./pages/Index3/Index3";
import Index4 from "./pages/Index4/Index4";
import Index5 from "./pages/Index5/Index5";
import Index6 from "./pages/Index6/Index6";
import Started from "./pages/GetStarted/start";
import Customer from "./pages/cusService/index";
import About from "./pages/AboutUs/index";
import Contact from "./pages/ContactUs/index";
// import Login from "./pages/login/index";
import Signup from "./pages/signup/index";
import Services from "./pages/services/Index1";

import Bookkeeping from "./pages/bookkeeping/Index1";
import GeneralAdmin from "./pages/generaladmin/Index1";
import Telemarketing from "./pages/Telemarketing/Index1";
import ProductDesigner from "./pages/productdesigner/Index1";
import RealEstate from "./pages/realestate/Index1";
import SocialMedia from "./pages/socialmedia/Index1";
import WebDev from "./pages/webdev/Index1";
const routes = [
  { path: "/index6", component: Index6 },
  { path: "/index5", component: Index5 },
  { path: "/index4", component: Index4 },
  { path: "/index3", component: Index3 },
  { path: "/index2", component: Index2 },
  { path: "/customerServices", component: Services },
  { path: "/bookacall", component: Started },
  { path: "/Aboutus", component: About },
  { path: "/Contactus", component: Contact },
  // { path: "/", component: Login },
  { path: "/signup", component: Signup },
  { path: "/telemarketing", component: Telemarketing },
  { path: "/coldcalling", component: Started },
  { path: "/generaladmin", component: GeneralAdmin },
  { path: "/bookkeeping", component: Bookkeeping },
  { path: "/realestate", component: RealEstate },
  { path: "/weddev", component: WebDev },
  { path: "/socialmedia", component: SocialMedia },
  { path: "/productdesign", component: ProductDesigner },

  { path: "/", component: Index1 },
];

export default routes;
