import React, { Component ,useState} from 'react';
import { Container, Row, Col} from "reactstrap";
import { Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";
import homeImg from "../../assets/images/image6.png";

const Faqs = () => {
    
        return (
            <React.Fragment>
                          <section className="section " id="services" style={{ background: `url(${homeImg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: '100%' }}>
            <Container>
            <Row className="justify-content-center">
                            <Col lg={12}>
                                <div className="hero-wrapper mb-4">
                                   
                                    <h1 className="hero-title mb-4" style={{textAlign:'center', color:'white'}}>About Us</h1>

   

                                   
                                </div>

                            </Col>

                            {/* <Col lg={6} sm={8} >
                                <div className="home-img mt-5 mt-lg-0" style={{marginLeft:'auto'}}>
                                <div className="mt-4" >
                                    <Link to="#" className="btn  mt-2 mr-2 ml-1" style={{backgroundColor:'#A47229', color:'#ffff'}}>Book Demo</Link>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
               
                

                
            </Container>
            
        </section>
                      <section className="section " id="services" style={{backgroundColor:'#FFFF'}}>
            <Container>
            <Row className="justify-content-center">
                            <Col lg={12}>
                                <div className="hero-wrapper mb-4">
                                   
                                    <h1 className="hero-title mb-4" style={{color:'#02035E'}}>Who we are?</h1>

                                    <p style={{color:"#02035E"}}>Welcome to Best Assistant USA, where we bring innovation, efficiency, and a personal touch to the world 
of virtual support. Our team is composed of dedicated professionals committed to delivering top-notch 
virtual assistance services tailored to meet the unique needs of our clients. With a passion for excellence 
and a focus on building lasting relationships, we strive to be your trusted partner in navigating the digital 
landscape. Best Assistant USA is not just a service provider; we are your dedicated ally in navigating the 
complexities of the digital age. Comprised of a dynamic team of seasoned professionals, we bring a wealth 
of experience, expertise, and a genuine passion for helping individuals and businesses thrive in a virtual 
landscape.
</p>

                                   
                                </div>

                            </Col>

                            {/* <Col lg={6} sm={8} >
                                <div className="home-img mt-5 mt-lg-0" style={{marginLeft:'auto'}}>
                                <div className="mt-4" >
                                    <Link to="#" className="btn  mt-2 mr-2 ml-1" style={{backgroundColor:'#A47229', color:'#ffff'}}>Book Demo</Link>
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
               
                

                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }


export default Faqs;