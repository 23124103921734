import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";
import Img1 from "../../assets/images/Vector (1).png";
import Img2 from "../../assets/images/Telemarketing.png";
import Img3 from "../../assets/images/Button container.png";
import Img4 from "../../assets/images/Button container (1).png";
import Img5 from "../../assets/images/Button container (2).png";
import Img6 from "../../assets/images/Simplification.png";
import Img7 from "../../assets/images/code.png";
import Img8 from "../../assets/images/Telemarketing.png";
import Img9 from "../../assets/images/Vector (2).png";

class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { url : '/customerServices', icon : Img1, title : "Customer Service", desc : "We assist customers promptly and ensure customer satisfaction through effective communication and support." },
                { url : '/telemarketing',icon : Img2, title : "Telemarketing", desc : "We help you engage prospects, generate leads, and drive sales by effectively communication." },
                { url : '/',icon : Img3, title : "Cold Calling", desc : "We provide a direct marketing technique that helps you create opportunities and close business deals." },
                { url : '/',icon : Img4, title : "Lead Generation", desc : "We provide uncommon strategies and tactics aimed at identifying and cultivating potential customers for your business." },
                { url : '/bookkeeping',icon : Img5, title : "Book Keeping", desc : "We provide systematic recording, organizing, and managing of your company's financial transactions and records." },
                
                {url : '/realestate', icon : Img6, title : "Real Estate", desc : "Our services include property sales and purchases, property management, real estate marketing, and consultancy." },
               
                {url : '/weddev', icon : Img7, title : "Web Development", desc : "We provide visual appealing, and user-friendly websites tailored to meet the needs of businesses or certain individuals." },
               
                {url : '/socialmedia', icon : Img8, title : "Social Media Management", desc : "Our goal here is to strengthen brand awareness, increase customer engagement, drive website traffic, and ultimately contribute to achieving your business objectives." },
            
                {url : '/productdesign', icon : Img9, title : "Product Designer", desc : "We create innovative, user-centric and functional designs across various industries to solve specific problems or fulfill specific business or individual needs." },
            
            ]
        }
    }
    
    render() {
        return (
            <React.Fragment>
        <section className="section bg-light" id="services">
            <Container>
            {/* <SectionTitle
                // subtitle="Services"
                title="Services We Provide"
                desc="It will be as simple as occidental in fact, it will be Occidental."
              
            /> */}
             <h1 className="hero-title mb-4">Our Services</h1>
             <p className='mb-4' style={{textAlign:'left', color:"#02035E"}}>We have efficient and effective solutions that are tailored <br/> to simplify your work and enhance your productivity. <br/>We can also help you revolutionize how you manage tasks!</p>


                <Row>
                    <ServiceBox services={this.state.services} />
                </Row>
                

                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Process;

