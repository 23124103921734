import React, { Component } from "react";
import routes from "./routes";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";

// Import Scss   localStorage.setItem('userInfo', userInfo.full_name);
import "./theme.scss";

//Import Icon Css
import "./assets/css/materialdesignicons.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
        <ToastContainer />
          <Switch>
         
            {routes.map((route, idx) => (
              
              <Route path={route.path} component={route.component} key={idx} />
            ))}
            
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);