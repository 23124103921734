import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
// import logolight from "../../assets/images/logo-light.png";

class FooterLinks extends Component {
    render() {
        return (
            <React.Fragment>
        <section className=" py-3" style={{backgroundColor:'#A472291A',color:"#252523"}}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="float-sm-left">
                        <p className="copyright-desc mb-0"> ©{new Date().getFullYear()} Best Assistant USA, Inc. - All Rights Reserved</p>
                            {/* <Link to="#">
                                <img src={logolight} alt="" height="20"/>
                            </Link> */}
                        </div>
                        <div className="float-sm-right mt-4 mt-sm-0" >

                            <Row>
                    {/* <Col md={7} > 
                    <p className="copyright-desc mb-0">
                    <Link to="#" style={{color:"#252523", fontSize:"10px"}}>
                    Terms of Use
                            </Link></p>
                            </Col> */}
                    <Col md={5} >
                    <p className="copyright-desc mb-0">
                    <Link to="#" style={{color:"#252523", fontSize:"10px"}}>
                              Privacy 
                              </Link></p>
                    </Col>
                    </Row>
                        </div>
                    </Col>
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default FooterLinks;