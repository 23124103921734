import React, { Component } from 'react';
import { Col } from "reactstrap";
// import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";
import "./service.css";
class ServiceBox extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.services.map((service, key) =>
                    <Col xl={4} sm={6} key={key}  className="serve">
                        <Link to={service.url} >
                        <div className="text-left p-4 mt-3">
                            <div className="avatar-md mx-left mb-4" >
                                <span className="avatar-title rounded-circle" style={{backgroundColor:'#A472291A'}}>
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={service.icon} alt=""  />
                                    </i>
                                </span>
                            </div>
                            <h5 className="font-18">{service.title}</h5>
                            <p className="mb-0" style={{color:"#02035E"}}>{service.desc}</p>
                        </div>
                        </Link>
                    </Col>
                    )
                }        
            </React.Fragment>
        );
    }
}

export default ServiceBox;