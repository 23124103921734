import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import FeatureBox from "./feature-box";

//Import Images
import feature1 from "../../assets/images/image4.png";
import feature2 from "../../assets/images/image5.png";
import value1 from "../../assets/images/value1.png";
import value2 from "../../assets/images/value2.png";
import value3 from "../../assets/images/value3.png";

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ],
            smallFeatures2 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                 <section className="section" id="features">
            <Container>
            {/* <SectionTitle
                subtitle="Features"
                title="Key features of the product"
                desc="It will be as simple as occidental in fact, it will be Occidental."
            />                */}

                <Row>
                <Col lg={12}>
                <div className="hero-wrapper ">
                                   
                                   <h1 className="hero-title " style={{color:'#02035E', textAlign:'center'}}>Our Values</h1>
                                   </div>
                </Col>
              
                    <Col lg={4}>
                    <Card className="border border-light shadow-none mt-5 mt-lg-0">
                         
                            <CardBody className="bg-light">
                                <div className="box-shadow">

                                    {/* <img src={feature1} alt="" className="img-fluid mx-auto d-block"/> */}

                                    <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}>
                                <span className="avatar-title rounded-circle bg-soft-primary ">
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={value1} alt="" className="icon-dual-primary " />
                                    </i>
                                </span>
                            </div>
                            <h5 className="font-18">Integrity</h5>
                            <p className="mb-0" style={{color:"#02035E"}}>We uphold the highest standards of honesty, transparency, and ethical behavior in all our interactions. We commit to doing the right thing, even when faced with challenges or difficult decisions.</p>
                        </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={4} sm={4} className="ml-lg-auto">
                        <Card className="border border-light shadow-none mt-5 mt-lg-0">
                            {/* <CardHeader className="border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader> */}
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    {/* <img src={feature1} alt="" className="img-fluid mx-auto d-block"/> */}
                                    <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}>
                                <span className="avatar-title rounded-circle bg-soft-primary ">
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={value2} alt="" className="icon-dual-primary " />
                                    </i>
                                </span>
                            </div>
                            <h5 className="font-18">Innovation</h5>
                            <p className="mb-0" style={{color:"#02035E"}}>We encourage a culture of creativity and forward thinking. We embrace change and proactively seek innovative solutions to address challenges and drive progress.</p>
                        </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={4} sm={4} className="ml-lg-auto">
                        <Card className="border border-light shadow-none mt-5 mt-lg-0">
                            {/* <CardHeader className="border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader> */}
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    {/* <img src={feature1} alt="" className="img-fluid mx-auto d-block"/> */}
                                    <div className="text-center p-4 mt-3">
                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}>
                                <span className="avatar-title rounded-circle bg-soft-primary ">
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={value3} alt="" className="icon-dual-primary " />
                                    </i>
                                </span>
                            </div>
                            <h5 className="font-18">Collaboration</h5>
                            <p className="mb-0" style={{color:"#02035E"}}>We value teamwork and believe in the power of collective intelligence. We foster an inclusive and collaborative work environment, where diverse perspectives are respected.</p>
                        </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                

                
            </Container>
            
        </section>
        <section className="section" id="features">
            <Container>
            {/* <SectionTitle
                subtitle="Features"
                title="Key features of the product"
                desc="It will be as simple as occidental in fact, it will be Occidental."
            />                */}

                <Row>

                    <Col lg={5}>
                        <div>
                        <h1 className="hero-title mb-4">Mission</h1>

<p style={{color:"#02035E"}}>Building on our mission, we are dedicated to fostering 
a culture of continuous improvement and innovation. 
At Best Assistant USA, we know that the digital 
landscape is dynamic, and to truly help our clients, we 
commit to staying ahead of others. Our team is driven 
by a passion for exploring new technologies, refining 
our skills, and embracing new trends, ensuring that the 
solutions we provide are always at forefront of virtual 
assistance services</p>
                            {/* <FeatureBox icon="bar-chart-2" title="Improve your Marketing business" smallFeatures={this.state.smallFeatures1} desc="If several languages coalesce, the grammar of the resulting language is more simple and regular." link="#" /> */}
                        </div>
                    </Col>

                    <Col lg={5} sm={8} className="ml-lg-auto">
                        <Card className="border border-light shadow-none mt-5 mt-lg-0">
                            {/* <CardHeader className="border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader> */}
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature1} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                

                <Row className="mt-5 pt-5">
                    <Col lg={5} sm={8}>
                        <Card className="border border-light shadow-none">
                            {/* <CardHeader className=" border-0 bg-transparent">
                                <div>
                                    <i className="mdi mdi-circle text-danger mr-1"></i>
                                    <i className="mdi mdi-circle text-warning mr-1 ml-1"></i>
                                    <i className="mdi mdi-circle text-success mr-1 ml-1"></i>
                                </div>
                            </CardHeader> */}
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature2} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={5} className="ml-lg-auto">
                        <div className="mt-4 mt-lg-0">
                        <h1 className="hero-title mb-4">Vision</h1>
                        <p style={{color:"#02035E"}}>Our vision is to be the leading virtual assistance 
provider, recognized for our commitment to 
excellence, innovation, and unparalleled customer 
satisfaction. We aspire to create a future where 
individuals and businesses thrive with the support of 
seamless and efficient virtual assistance services.</p>
<p style={{color:"#02035E"}}>
In pursuit of our vision, we are committed to staying 
agile and adaptable, anticipating the evolving needs of 
our clients and the dynamic nature of the digital 
landscape. By embracing cutting-edge technologies 
and staying abreast of industry trends, we position 
ourselves as architects of transformative solutions 
that propel our clients toward sustained success
</p>
                        {/* <FeatureBox icon="pie-chart" title="Increase your Marketing Performance" smallFeatures={this.state.smallFeatures2} desc="If several languages coalesce, the grammar of the resulting language is more simple and regular." link="#" /> */}
                        </div>
                    </Col>

                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Features;