import React, { useState } from 'react';
import { Container, Row, Col, } from "reactstrap";

import { Collapse, Select } from 'antd';
const { Option } = Select;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const Faq = () => {
      const [expandIconPosition, setExpandIconPosition] = React.useState('end');
      const items = [
        {
          key: '1',
          label: ' What is "Best Assistant USA," and how does it work?',
          children: <div>Best Assistant USA is a team of highly skilled workers who are experts in their field. We connect businesses to outsourced workers, and handle hiring, training, and matching remote talent to job openings, which boosts productivity and reduces management responsibilities.
          </div>, 
       
        },
        {
          key: '2',
          label: 'How can I get started on the "Best Assistant USA" platform?',
          children: <div>Looking for a virtual assistant can feel like a lot of work, but there are actually just a few steps to get started and find your assistant. You’ll need to identify the tasks to perform, then set up your own business information by signing in, and click on schedule a meeting to talk to your assistant.</div>,
         
        },
        {
          key: '3',
          label: 'What types of tasks can I find assistance for on your platform?',
          children: <div>When you navigate through our websites, you would find all services we offer on our 'services tab' or just by scrolling through the landing page. You can also find more details about our services by clicking on the service.</div>,
          
        },
        {
          key: '4',
          label: 'How are payments handled on the platform?',
          children: <div>After talking to a representative and you virtual assistant over a scheduled zoom meeting on the platform, you will be charged for the service and payment shall be made on the platform. You shall receive an invoice as soon as the payment is successful.</div>,
          
        },
        {
          key: '5',
          label: 'How qualified are your virtual assistants?',
          children: <div>Our virtual assistants come from diverse backgrounds, have experience in various industries, and have undergone a rigorous selection process to ensure they possess the necessary skills, experience, and professionalism to meet individual client's needs.</div>,
          
        },
        {
          key: '6',
          label: 'How secure is my information with your Organization?',
          children: <div>You can rest assured that we take data security and confidentiality seriously. 
          Strict non-disclosure agreements (NDAs) bind our virtual assistants, and we have implemented robust security measures to safeguard data transmission and storage.</div>,
          
        },
        {
          key: '7',
          label: "Do I get a refund if I'm not satisfied with my assigned virtual assistant?",
          children: <div>Our primary objective is to ensure that our customers are satisfied. However, if you feel dissatisfied with our services in any way, please reach out to us, and we will address your concerns promptly. We either assign you to a different virtual assistant or take other appropriate measures to ensure your satisfaction with our services.</div>,
          
        },
        {
          key: '8',
          label: 'Can I choose a virtual assistant myself?',
          children: <div>Yes, by providing us with your preferences and requirements upon registration on our platform. We aim to pair you with a virtual assistant that can support you in achieving your goals and aligns with your workflow effectively.</div>,
          
        },
      ];
        return (
            <React.Fragment>
        <section className="section" style={{backgroundColor:'#fffff'}}>
            <Container>
                <Row className="justify-content-center">
                <Col lg={8}>
                        <div className="text-left mb-5">
                            <h4 className="mb-3">Frequently asked questions</h4>
                            <p>If you have any other questions or feedback, please contact us and we’ll get back to you shortly.</p>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="text-left mb-5">
                        <Collapse
        defaultActiveKey={['1']}
        accordion 

        expandIconPosition={expandIconPosition}
        items={items}
      />
                        </div>
                    </Col>

                   
                </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }


export default Faq




