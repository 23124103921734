import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col
} from "reactstrap";


//Import Images
import homeImg from "../../assets/images/tele1.png";


class Section extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="hero-section" id="home"  style={{ background: `url(${homeImg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: '100%' }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4">
                                   
                                    <h1 className="hero-title mb-4" style={{color:'#02035E'}}>Welcome to your trusted
Telemarketing Virtual 
Assistant!</h1>

                                    {/* <p style={{color:'#000000'}}>Effortless Efficiency, Personalized Assistance</p> */}
<br/>
                                    <div className="mt-5">
                                    <Link to="/signup" className="btn  mt-2 mr-2 ml-1" style={{backgroundColor:'#A47229', color:'#ffff'}}>Get Started</Link>
                                        <Link to="/aboutus" className=" mt-2 mr-4" style={{color:'#02035E', fontWeight:'bolder'}}>Learn More</Link>
                                        
                                    </div>
                                </div>

                            </Col>

                            <Col lg={6} sm={8}>
                                <div className="home-img mt-5 mt-lg-0">
                                    {/* <img src={homeImg} alt="" className="img-fluid mx-auto d-block" /> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;