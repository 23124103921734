import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Media } from "reactstrap";
import { FacebookOutlined,InstagramOutlined,XOutlined,TikTokOutlined} from '@ant-design/icons';
import logolight from "../../assets/images/logo.png";
//Import Footer link
import { BsSnapchat } from "react-icons/bs";
import FooterLinks from "./footer-links";

class Footer extends Component {
    state = {
        links1 : [
            { link : "/customerservices", title : " Customer Service" },
            { link : "/telemarkting", title : "Telemarketing" },
            { link : "/bookkeeping", title : "Book Keeping" },
            { link : "/webdev", title : "Web Development" },
            { link : "/productdesign", title : "Product Design" },
         



        ],
        links2 : [
            { link : "/aboutus", title : "About" },
            // { link : "#", title : "Terms" },
            { link : "#", title : "Privacy" },
            { link : "/contactus", title : "Contact" },
           
        ],
        links3 : [
            { link : "https://www.facebook.com/profile.php?id=61557373006204",icon:<FacebookOutlined style={{fontSize:25}}/>, title : "Facebook" },
            { link : "https://www.instagram.com/bestassistant_usa/",icon:<InstagramOutlined style={{fontSize:25}}/>, title : "Instagram" },
            { link : "https://twitter.com/Bestwatches_usa",icon:<XOutlined style={{fontSize:25}}/>, title : "Twitter" },
            { link : "https://www.tiktok.com/@bestassistantusa?lang=en", icon:<TikTokOutlined style={{fontSize:25}}/>,title : "TikTok" },
            { link : "https://web.snapchat.com/?ref=snapchat_dot_com_login_cta", icon:<BsSnapchat style={{fontSize:25}}/>,title : "Snapchat" },
        ],
        
         
        
        
      




    }
    render() {
        return (
            <React.Fragment>
        <footer className="footer" style={{backgroundColor:'#A472291A'}}>
            <Container>
                <Row>
                    <Col lg={4} sm={6} >
                        <div style={{color:"#252523"}}>
                        <Row>
                    <Col lg={4} sm={6} > <Link to="/">
                                <img src={logolight} alt=""/>
                            </Link></Col>
                    <Col lg={5} sm={6} > <h5 className="mt-3 footer-list-title" style={{color:"#252523"}}>Best Assistant USA</h5></Col>
                    </Row>
                           
                            <p className="mt-3">Your Premium Virtual Assistance</p>
                        </div>
                    </Col>
                    <Col lg={{size:2, offset:1}} sm={6}>
                        <div>
                            <h5 className="mb-4 footer-list-title" style={{color:"#252523"}}>Use Case</h5>
                            <ul className="list-unstyled footer-list-menu" >
                                {
                                    this.state.links1.map((fLink, key) =>
                                        <li key={key} ><Link to={fLink.link} style={{color:"#252523"}}>{fLink.title}</Link></li>
                                    )
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} sm={6} >
                        <div>
                            <h5 className="mb-4 footer-list-title" style={{color:"#252523"}}>Company</h5>
                            <ul className="list-unstyled footer-list-menu">
                                {
                                    this.state.links2.map((fLink, key) =>
                                        <li key={key}><Link to={fLink.link} style={{color:"#252523"}}>{fLink.title}</Link></li>
                                    )
                                }
                            </ul>
                        </div>
                    </Col>

                    <Col lg={3} sm={6}>
                        <div>
                            <h5 className="mb-4 footer-list-title" style={{color:"#252523"}}> Socials</h5>
                        
                            <ul className="list-unstyled footer-list-menu" style={{display:'flex'}}>
                                {
                                    this.state.links3.map((fLink, key) =>
                                        <li key={key} style={{padding:'10px'}}><a href={fLink.link} style={{color:"#252523"}}> {fLink.icon}</a></li>
                                    )
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
                {/* Render footer links */}
                <FooterLinks/>
            </React.Fragment>
        );
    }
}

export default Footer;