import React, { Component } from 'react';
import { Container, Row, Col, Form, Input, Button,Card, CardBody, Media } from "reactstrap";
import step1 from "../../assets/images/b6.png";
import step2 from "../../assets/images/b6.png";
import step3 from "../../assets/images/b6.png";
import { Link } from "react-router-dom";
class Subscribe extends Component {
    render() {
        return (
            <React.Fragment>
        <section className="section" style={{backgroundColor:'rgba(164, 114, 41, 0.1)'}}>
            <Container>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <div className="text-center mb-5">
                            {/* <h5 className="text-primary text-uppercase small-title">Subscribe</h5> */}
                            <h4 className="mb-3">What Can Web Developer VAs Do?</h4>
                            <p>Using a VA to help manage your customer service saves you time and money and means<br/> that you can deliver a better customer experience.</p>
                        </div>
                    </Col>

                    <Col xl={8} >
                        <div className="text-center">
                            <div className="subscribe">
                            <Row>
                                        
                                        <Col md={12}>
                                            <div className="mt-3 mt-md-0">
                                            <Link to="/signup"> <Button type="submit"  style={{backgroundColor:'#A47229', width:150, borderRadius:25, margin:'auto'}} block>Get Started</Button></Link>
                                            </div>
                                        </Col>
                                    </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{width:1200, margin:'auto'}}>
                        <Col  sm={4}>
                               

                                <div  className="item"  style={{marginTop:30}}>
                            {/* <Card > */}
                                <div className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                {/* <span className="avatar-title rounded-circle  " style={{backgroundColor:'#A472291A'}}> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step1} alt="" className="icon-dual-primary "  style={{width:55}} />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        {/* <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Book a call</p> */}
                                            <h5 className="font-16"> Data Scraping</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </div>
                            {/* </Card> */}
                        </div>
                        <div  className="item"  style={{marginTop:-35}}>
                            {/* <Card > */}
                                <div className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                {/* <span className="avatar-title rounded-circle  " style={{backgroundColor:'#A472291A'}}> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step2} alt="" className="icon-dual-primary "  style={{width:55}} />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        {/* <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Subscribe to a plan</p> */}
                                            <h5 className="font-16"> Java scripting</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </div>
                            {/* </Card> */}
                        </div>
                        <div  className="item"  style={{marginTop:-35}}>
                            {/* <Card > */}
                                <div className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                
                                {/* <span className="avatar-title rounded-circle bg-soft-primary "> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step3} alt="" className="icon-dual-primary "  style={{width:55}} />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        {/* <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Submit your request</p> */}
                                            <h5 className="font-16">Testing web applications</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </div>
                            {/* </Card> */}
                        </div>
                            </Col>
                            <Col sm={4}>
                            <div  className="item"  style={{marginTop:30}}>
                            {/* <Card > */}
                                <div className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                {/* <span className="avatar-title rounded-circle  " style={{backgroundColor:'#A472291A'}}> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step1} alt="" className="icon-dual-primary "  style={{width:55}} />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        {/* <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Book a call</p> */}
                                            <h5 className="font-16"> Collaborating with designers, <br/>
developers, and stakeholders</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </div>
                            {/* </Card> */}
                        </div>
                        <div  className="item"  style={{marginTop:-35}}>
                            {/* <Card > */}
                                <div className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                {/* <span className="avatar-title rounded-circle  " style={{backgroundColor:'#A472291A'}}> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step2} alt="" className="icon-dual-primary "  style={{width:55}} />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        {/* <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Subscribe to a plan</p> */}
                                            <h5 className="font-16"> Designing user interfaces<br/>
and navigation menus
</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </div>
                            {/* </Card> */}
                        </div>
                        <div  className="item"  style={{marginTop:-35}}>
                            {/* <Card > */}
                                <div className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                     
                                    <i>
                                 
                                    <img src={step3} alt="" className="icon-dual-primary "  style={{width:55}} />
                                    </i>
                     
                                        </div>
                                        <Media body className="align-self-center">
                                      
                                            <h5 className="font-16">Integrating multimedia content<br/> onto a site</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </div>
                            {/* </Card> */}
                        </div>

                            </Col>

                           
                        </Row>
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Subscribe;