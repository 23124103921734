import React, { Component ,useState} from 'react';
import { Container, Row, Col} from "reactstrap";
import { Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";
import step1 from "../../assets/images/steps1.png";
import step2 from "../../assets/images/steps2.png";
import step3 from "../../assets/images/steps3.png";
const Faqs = () => {
    
        return (
            <React.Fragment>
                <section className="section" style={{backgroundColor:'#ffff'}}  >
                    <Container>
                        <Row className="justify-content-center">
                        <Col lg={6} sm={8}>
                                <div className="home-img mt-5 mt-lg-0">
                                    {/* <img src={homeImg} alt="" className="img-fluid mx-auto d-block" /> */}
                                </div>

                                <div  className="item"  >
                            <Card style={{backgroundColor:'#A472291A'}}>
                                <CardBody className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                {/* <span className="avatar-title rounded-circle  " style={{backgroundColor:'#A472291A'}}> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step1} alt="" className="icon-dual-primary "  />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Book a call</p>
                                            <h5 className="font-16"> Seize the moment, schedule your call now!</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </CardBody>
                            </Card>
                        </div>
                        <div  className="item"  >
                            <Card style={{backgroundColor:'#A472291A'}}>
                                <CardBody className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                {/* <span className="avatar-title rounded-circle  " style={{backgroundColor:'#A472291A'}}> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step2} alt="" className="icon-dual-primary mt-2" />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Subscribe to a plan</p>
                                            <h5 className="font-16"> Unlock exclusive benefits, subscribe to a plan now!</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </CardBody>
                            </Card>
                        </div>
                        <div  className="item"  >
                            <Card style={{backgroundColor:'#A472291A'}}>
                                <CardBody className="p-4">
                                    
                                    <Media className="pt-3">
                                        <div className="avatar-md mr-3">
                                            {/* <span className="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                                           
                                            </span>
                                            <div className="avatar-md mx-center mb-4" style={{margin:'auto'}}> */}
                                
                                {/* <span className="avatar-title rounded-circle bg-soft-primary "> */}
                                    <i>
                                    {/* <FeatherIcon icon={service.icon} className="icon-dual-primary" /> */}
                                    <img src={step3} alt="" className="icon-dual-primary mt-4" />
                                    </i>
                                {/* </span> */}
                            {/* </div> */}
                                        </div>
                                        <Media body className="align-self-center">
                                        <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Submit your request</p>
                                            <h5 className="font-16">Submit your request and let's make it happen together.</h5>
                                         
                                        </Media>
                                      
                                    </Media>
                                </CardBody>
                            </Card>
                        </div>
                            </Col>
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4 ">
                                   
                                    <h1 className="hero-title mb-4" style={{marginTop:'100px'}}>A few simple steps to get started.</h1>

                                    <p>Ready to begin? Let's kick-start your journey today. 
Get started and embrace our best virtual experience.</p>

                                    <div className="mt-4">
                                    <Link to="/signup" className="btn  mt-2 mr-2 ml-1" style={{backgroundColor:'#A47229', color:'#ffff'}}>Get Started</Link>
                                   
                                        
                                    </div>
                                </div>

                            </Col>

                           
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }


export default Faqs;