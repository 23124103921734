import React, { Component ,useState} from 'react';
import { Container, Row, Col} from "reactstrap";
import './step.css'
import { Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";
import serve1 from "../../assets/images/serve1.png";
import serve2 from "../../assets/images/serve2.png";
import serve3 from "../../assets/images/serve3.png";
import serve4 from "../../assets/images/serve4.png";
import serve5 from "../../assets/images/serve5.png";
import serve6 from "../../assets/images/serve6.png";
import serve7 from "../../assets/images/serve7.png";
import serve8 from "../../assets/images/serve8.png";
import serve9 from "../../assets/images/serve9.png";
import serve10 from "../../assets/images/serve10.png";
import feature1 from "../../assets/images/est2.png";
import feature2 from "../../assets/images/est3.png";
import value1 from "../../assets/images/value1.png";
import value2 from "../../assets/images/value2.png";
import value3 from "../../assets/images/value3.png";
const Faqs = () => {
    
        return (
            <React.Fragment>
                <section className="section" style={{backgroundColor:'#ffff'}}  >
                    <Container>
                        <Row className="justify-content-center">
                        <Col lg={6} sm={8}>
                              

                            
                        <div className="hero-wrapper  ">
                                   
                                   <h1 className="hero-title mb-4" style={{marginTop:'60px', color:'rgba(2, 3, 94, 1)'}}>Our Real Estate VA Tools​​</h1>

                                   <p style={{marginTop:'15px'}}>Ready to begin? Let's kick-start your journey today. 
Get started and embrace our best virtual experience.</p>

                                  
                               </div>
                       
                            </Col>


                            <Col lg={6} sm={8}>
                            <div className="hero-wrapper mt-5 ">
                                   
                                   <Row style={{marginTop:10}}>
                                   <Col sm={4}><img src={serve10} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   <Col sm={4}><img src={serve2} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   <Col sm={4}><img src={serve4} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   </Row>
                                 
                                   <Row style={{marginTop:10}}>
                                   <Col sm={4}><img src={serve3} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   <Col sm={4}><img src={serve5} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   <Col sm={4}><img src={serve6} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   </Row>
                                  
                                   <Row style={{marginTop:10}}>
                                   <Col sm={4}><img src={serve7} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   <Col sm={4}><img src={serve8} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   <Col sm={4}><img src={serve9} alt="" className="icon-dual-primary " style={{width:120}} /></Col>
                                   </Row>

                                  
                               </div>
                            </Col>

                           
                        </Row>
                    </Container>
                </section>

           
        <section className="section" id="features" style={{marginTop:-45}}>
            <Container>
      
                <Row>

                    <Col lg={6}>
                       
                    <div  className="item"  style={{marginTop:20}}>
                            <Card >
                                <CardBody className="p-4">
                                    
                                    <Media className="pt-0">
                                      
                                        <Media body className="align-self-center">
                                        <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Save Money</p>
                                            <p> Save as much as 70% when you use a general admin virtual assistant versus hiring in-house.</p>
                                         
                                        </Media>
                                      
                                    </Media>
                                </CardBody>
                            </Card>
                        </div>
                        <div  className="item"  >
                            <Card >
                                <CardBody className="p-4">
                                    
                                    <Media className="pt-0">
                                      
                                        <Media body className="align-self-center">
                                        <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>Time Efficiency</p>
                                            <p> Delegating routine and time-consuming tasks to a virtual assistant allows real estate professionals to focus on high-priority activities</p>
                                         
                                        </Media>
                                      
                                    </Media>
                                </CardBody>
                            </Card>
                        </div>
                        <div  className="item"  style={{marginTop:-25}} >
                            <Card >
                                <CardBody className="p-4">
                                    
                                    <Media className="pt-0">
                                       
                                        <Media body className="align-self-center">
                                        <p className="mb-8 mr-9" style={{color:'black', fontWeight:'bolder'}}>More Flexibility</p>
                                            <p > Hire as few or as many VAs for book keeping as you need.</p>
                                         
                                        </Media>
                                      
                                    </Media>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>

                    <Col  sm={6} className="ml-lg-auto">
                        <Card className="border border-light shadow-none mt-5 mt-lg-0">
                         
                            <CardBody className="bg-light">
                                <div className="box-shadow">
                                    <img src={feature1} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row style={{width:'100%'}}>
                <Col  sm={8} className="ml-lg-auto" >
                    <div class="containering" style={{width:'600px'}}>
	  <div class="outer" > 
      <img src={feature2} alt="" className="img-fluid mx-auto d-block" style={{ borderRadius:20}} />
      {/* <Card className="border border-light shadow-none mt-5 mt-lg-0">
     
     <CardBody className="bg-light">
         <div className="box-shadow">
             <img src={feature2} alt="" className="img-fluid mx-auto d-block"/>
           
         </div>
         
     </CardBody>
 </Card> */}
 
      </div>
	  <div class="inner"> 
      <div style={{marginTop:-70, width:320, marginLeft:30}}>
                                   
                                   <h3 className="hero-title mb-4" style={{marginTop:'100px', color:'rgba(2, 3, 94, 1)'}}>Our mission is to help you grow your business​</h3>

                                   <p>Our mission is to enhance productivity, streamline tasks, and provide personalized and efficient support through innovative virtual assistance solutions..</p>

                                  
                               </div>

      </div>
	</div>
   
   
</Col>
<Col lg={4}>
   

</Col>


</Row>
                

       
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }


export default Faqs;