import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./benefit-box";
import Img1 from "../../assets/images/Vector (1).png";
import Img2 from "../../assets/images/Telemarketing.png";
import Img3 from "../../assets/images/Button container.png";
import Img4 from "../../assets/images/Button container (1).png";
import Img5 from "../../assets/images/Button container (2).png";
import Img6 from "../../assets/images/Simplification.png";


class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : [
                { icon : Img1, title : "Seamless Integration", desc : "This is where Efficiency meets Simplicity." },
                { icon : Img2, title : "Fixed monthly rate", desc : "Enjoy peace of mind with our fixed monthly rate – no surprises, just reliability." },
                { icon : Img3, title : "Fast Services ", desc : "Swift solutions at your fingertips – experience our rapid service response." },
                { icon : Img4, title : "Collaborate with your team", desc : "Experience productivity through collaborative synergy – together, we achieve great success." },
                { icon : Img5, title : "Enhanced Security", desc : "With us you can be assured of a fortified protection with our enhanced security measures." },
                
                { icon : Img6, title : "Personalized Assistance", desc : "Tailored support just for you - Experience our personalized assistance, crafted to match your unique needs." },
               
               
            ]
        }
    }
    
    render() {
        return (
            <React.Fragment>
        <section className="section " id="services" style={{backgroundColor:'#A472291A'}}>
            <Container>
            <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="hero-wrapper mb-4">
                                   
                                    <h1 className="hero-title mb-4">Our Benefits</h1>

                                    <p style={{color:"#02035E"}}>Welcome to a world of unparalleled convenience! 
Our virtual assistance website is designed with features that will 
transform the way you work. Here's what our customers love.</p>

                                   
                                </div>

                            </Col>

                            <Col lg={6} sm={8} >
                                <div className="home-img mt-5 mt-lg-0" style={{marginLeft:'auto'}}>
                                <div className="mt-4" >
                                    <Link to="#" className="btn  mt-2 mr-2 ml-1" style={{backgroundColor:'#A47229', color:'#ffff'}}>Book Demo</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                <Row>
                    <ServiceBox services={this.state.services} />
                </Row>
                

                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Process;





