import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Import Section Title
import SectionTitle from "../common/section-title";
import Counter from "../../components/Counter/counter";
import ClientBox from "./client-box";

//Import Images
import clinet1 from "../../assets/images/clients/1.png";
import clinet3 from "../../assets/images/clients/3.png";
import clinet4 from "../../assets/images/clients/4.png";
import clinet6 from "../../assets/images/clients/6.png";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [
                
                { name: "Marie Clay", post: "Invoza User", desc: "I have been consistently impressed with the level of service provided by the customer service team. Their team is not only knowledgeable but also incredibly friendly and efficient. They have become an integral part of our business operations, helping us maintain high levels of customer satisfaction." },
                { name: "Godswill Freeman", post: "Invoza User", desc: "Our experience with the bookkeeping service has been nothing short of outstanding. They have transformed our financial processes, providing accurate and timely reports that have helped us make informed business decisions. Their attention to detail and expertise have been invaluable to our company." },
                { name: "Carie Stanley", post: "Invoza User", desc: "I've worked with several real estate agents in the past, but none have impressed me as much as this real estate service. Their attention to detail, market knowledge, and negotiation skills are second to none. They not only helped me sell my property quickly but also secured a great price. I wouldn't hesitate to work with them again in the future" },
                { name: "John Smith", post: "Invoza User", desc: "The real estate service went above and beyond to help us find our dream home. Their knowledge of the market and dedication to finding the perfect property for us was evident throughout the entire process. We couldn't be happier with the outcome and highly recommend their services to anyone in need of a trustworthy real estate agent." },
                { name: "Victoria Jensen", post: "Invoza User", desc: "We've seen a noticeable increase in sales since partnering with the telemarketing service. Their team is incredibly skilled at engaging potential customers and effectively communicating the value of our products. They've exceeded our expectations and have been a pleasure to work with. I highly recommend their services to any company looking to boost their sales efforts." },
                { name: "Walter Cameron", post: "Invoza User", desc: "Partnering with the telemarketing service was a game-changer for our business. Their proactive approach and dedication to generating leads have helped us reach new customers and expand our market reach. Their team is highly skilled and always goes above and beyond to deliver exceptional results. I highly recommend their services to any company looking to increase sales." },
                { name: "Joel Bryan", post: "Invoza User", desc: "The customer servicing exceeded our expectations! Their team consistently goes above and beyond to ensure our clients receive exceptional support. They are professional, responsive, and truly understand the importance of customer satisfaction." },
                { name: "Isabel Roshev", post: "Invoza User", desc: "I cannot speak highly enough of the bookkeeping service. Their team is incredibly knowledgeable and efficient, and they have saved us countless hours of work. With their help, our financial records are now organized and up-to-date, giving us peace of mind and allowing us to focus on growing our business." },
            ],
            clients2: [
                { image: clinet1 },
                { image: clinet3 },
                { image: clinet4 },
                { image: clinet6 },
            ],
            responsive: {
                576: {
                    items: 2
                },
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="clients">
                    <Container>
                        {/* <SectionTitle
                            subtitle="Testimonial"
                            title="What our Client Say"
                            desc="It will be as simple as occidental in fact, it will be Occidental."
                        /> */}

                        <Row>
                            <Col lg={12}>
                                <h5 className="mb-4"><i className="mdi mdi-format-quote-open text-primary h1 mr-1"></i> 3,500 + Satisfied Client</h5>

                                <OwlCarousel
                                    className="owl-theme testi-carousel"
                                    id="testi-carousel"
                                    items={1}
                                    loop={true}
                                    margin={10}
                                    nav={true}
                                    responsive={this.state.responsive}
                                >
                                    <ClientBox clients={this.state.clients} />
                                </OwlCarousel>
                            </Col>
                        </Row>


                        {/* <Row className="mt-5">
                            {
                                this.state.clients2.map((client, key) =>
                                    <Col xl={3} sm={6} key={key} >
                                        <div className="client-images">
                                            <img src={client.image} alt="client-img" className="mx-auto img-fluid d-block" />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row> */}

                    </Container>

                </section>

                {/* <section className="section bg-primary">
                    <Container>
                        <Row className="justify-content-center mb-5">
                            <Col lg={7}>
                                <div className="text-center text-white-50">
                                    <h4 className="text-white">Best Solutions for your Business</h4>
                                    <p>To achieve this, it would be necessary to have uniform grammar, pronunciation and more common that of the individual languages.</p>
                                </div>
                            </Col>
                        </Row>

                        <Counter />
                    </Container>

                </section> */}
            </React.Fragment>
        );
    }
}

export default Clients;