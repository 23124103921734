import React, { Component,useState ,Fragment} from 'react';
import { Link ,useHistory} from "react-router-dom";
import { Container, Row, Col,  FormGroup, Input, Button, Label, Card } from "reactstrap";
import LoadingPage from "../../components/loadingpage";
import { errorToast, successToast } from "../../components/toasts";
import { Form,Field, Formik, FormikErrors,ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
//Import Images
import { BASE_URL } from '../../config';
import bg1 from "../../assets/images/Testimonial.png";
import bg2 from "../../assets/images/Group.png";
import bg3 from "../../assets/images/GoogleLogo.png";
import bg4 from "../../assets/images/Line.png";
const SignupSchema = Yup.object().shape({
  full_name: Yup.string().required().min(4),
    phone: Yup.string().required(),
      email: Yup.string().required("Email is a required field")
      .email("Invalid email format"),
      password: Yup.string()
      .required()
      .min(8, "Password cannot be less than 8 characters"),
    password_confirmation: Yup.string()
      .required()
      .min(8, "Password cannot be less than 8 characters")
      .oneOf([Yup.ref("password")], "Passwords do not match."),
  });
  const Signup = () => {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const handleSubmit = (values) => {
      setLoading(true)
      // Alert the input values of the form that we filled
      axios({
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'post',
        url: `${BASE_URL}/api/signup`,
        data: values
      }).then((response) => {
        setTimeout(() => {

          setLoading(false);
         // history.push("https://dashboard.bestassistantusa.com/login");
          window.location.href="https://dashboard.bestassistantusa.com"
          successToast("Account Created Successfully");
        }, 3000);
      }, (error) => {
        errorToast(`${error}`)
        setLoading(false);
      });;
      
    };
        return (
            <React.Fragment>
                {/* <section className="hero-section-2" id="home" >
                    <Container> */}
                        <Row className="vertical-content justify-content-center">
                            <Col lg={6} style={{ background: `url(${bg1})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', height: '100%' }} >
                                {/* <div className="hero-wrapper mb-4">
                                
                                <img src={bg1} alt="" className="img-fluid mx-auto d-block"/>

                                   
                                </div> */}
                               < img src={bg2} alt="" className="img-fluid  d-block"/>

                            </Col>
                            {/* offset: 2 */}
                            <Col lg={{ size: 6,  }}>
                                
                                <Card className="mx-auto p-4 rounded mt-5 mt-lg-0" >
                                   
                                <Formik
                    validationSchema={SignupSchema}
                    initialValues={{ email: "", full_name: "" , phone: "", password: "" , password_confirmation: ""}}
                    
                    onSubmit= {handleSubmit}
                  >
                    {() => (
                      <Form className="space-y-4 max-w-[600px] mx-auto" >
                                    <div className="text-left">
                                    
                                       
                                        <h5 className="py-2 form-title mb-4 " style={{fontWeight:'50px'}}>Sign up</h5>
                                    </div>
                                        <FormGroup className="mb-4">
                                            {/* <Label className="font-15">Your Email*</Label> */}
                                            <Field type="email" style={{ height:'60px',borderRadius:'40px'}} className="form-control" id="email" name="email" placeholder="Email"  />
                                        </FormGroup>
                                        <ErrorMessage style={{color:'red'}} name="email" component="div" />
                                        <FormGroup className="mb-4">
                                            {/* <Label className="font-15">Your Email*</Label> */}
                                            <Field type="text" style={{ height:'50px',borderRadius:'40px'}} className="form-control" name="full_name" placeholder="Full Name"  />
                                        </FormGroup>
                                        <ErrorMessage style={{color:'red'}} name="full_name" component="div" />
                                        <FormGroup className="mb-4">
                                            {/* <Label className="font-15">Your Email*</Label> */}
                                            <Field type="tel" style={{ height:'50px',borderRadius:'40px'}} className="form-control" name="phone" placeholder="Phone Number"  />
                                        </FormGroup>
                                        <ErrorMessage style={{color:'red'}} name="phone" component="div" />
                                        <FormGroup className="mb-3">
                                            {/* <Label className="font-15">Already have an account?  Log in </Label> */}
                                            <Field type="password" style={{ height:'50px',borderRadius:'40px'}} className="form-control" name="password" placeholder="Password"  />
                                        </FormGroup>
                                        <ErrorMessage style={{color:'red'}} name="password" component="div" />
                                        <FormGroup className="mb-3">
                                            {/* <Label className="font-15">Already have an account?  Log in </Label> */}
                                            <Field type="password" style={{ height:'50px',borderRadius:'40px'}} className="form-control" name="password_confirmation" placeholder="Confirm Password"  />
                                        
                                        </FormGroup>
                                        <ErrorMessage style={{color:'red'}} name="password_confirmation" component="div" />

                                        <Fragment >
                          {loading ? (
                            <LoadingPage />
                          ) : (
                            <Button type="submit"  style={{backgroundColor:"#02035E", height:'50px',borderRadius:'40px'}} size="lg" block>Sign up </Button>
                          )}
                        </Fragment>
                                        
                                        <p className="text-right px-3 mt-2"><Label className="font-15" style={{color:'black'}}>Already have an account?   <a href='https://dashboard.bestassistantusa.com/login'>sign in</a> </Label></p>

                                        <p className=" mt-1" style={{display:'inline-flex', }}><span><img src={bg4} alt="" className="img-fluid mx-auto "/></span><span style={{ fontWeight:'bolder', marginLeft:'20px'}}>or</span><span><img src={bg4} alt="" className="img-fluid mx-auto " style={{width:'150px',marginLeft:'50px'}}/></span></p>
                                        <Button type="submit"  style={{borderColor:"#02035E", height:'60px',borderRadius:'40px', backgroundColor:'white'}}size="lg" block > <span><img src={bg3} alt="" className="img-fluid mx-auto "/></span>  <span style={{marginLeft:'10px',color:'black'}}>Sign up with Google</span></Button>
                                    </Form>
                                       )}
                                       </Formik>
                                    
                                </Card>
                            </Col>
                        </Row>
                    {/* </Container>
                </section> */}
            </React.Fragment>
        );
    }


export default Signup;